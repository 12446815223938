import { Component, ViewChild, ElementRef } from '@angular/core';
import { ConversationalForm } from 'conversational-form';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'myForm',
  template: `<div class="myCF" #myCF></div>`,
  styles: [`.myCF { position: relative; margin: auto; height: 95%; width: 100%; }`]
})
export class FormComponent  {
  @ViewChild('myCF') myCF: ElementRef;
  cf: any;
  token: any;
  formFields = [];


  constructor(
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.getChatbotConfiguration()
      .subscribe((data) => {
        if (data) {
          this.cf = ConversationalForm.startTheConversation({
            options: {
              submitCallback: this.submitCallback.bind(this),
              preventAutoFocus: true,
              robotImage: "//www.wewhodo.com/assets/slide-images/wwd-clothing-label-crimson.png",
            },
            tags: data["chatbot_configuration"],
          });
          this.myCF.nativeElement.appendChild(this.cf.el);
        }
      })
  }

  getChatbotConfiguration() {
    return this.http.get('https://wwd1.wewhodo.com/chatbot');
  }

  submitCallback() {
    var formDataSerialized = this.cf.getFormData(true);
    const data = { "feedback_response": JSON.stringify(formDataSerialized)}

    this.http.post('https://wwd1.wewhodo.com/submit_survey', data, { headers: { 'Content-Type': 'application/json' } })
      .subscribe(
        (data: any) => {
          this.cf.addRobotChatResponse("Thanks - you're done!&&We hope to see you at our next event!");
        },
        (err) => {
          this.cf.addRobotChatResponse("Sorry - something went wrong sending your answers...");
        }
      );
  }

}
